<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important"
            >
              <h4 class="text-capitalize breadcrumb-title">
                Documentation
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8"
                      >Action</span
                    >
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Documentation</span>
                  </li>
                </ul>
              </h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
          <h5>Table of Content</h5>
          <draggable
            class="dragArea list-group"
            :list="toc"
            :group="{ name: 'people', pull: 'clone', put: false }"
           
          >
            <div
              class="list-group-item"
              v-for="element in toc"
              :key="element.name"
            >
              {{ element.name }}
            </div>
          </draggable>
        </div>

        <div class="col-6">
          <h5>Pages</h5>
          <draggable
            class="dragArea list-group"
            :list="pages"
            group="people"
          >
            <div
              class="list-group-item"
              v-for="element in pages"
              :key="element.name"
            >
              {{ element.name }}
            </div>
          </draggable>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import db from "@/plugins/localdb";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  components:{
    draggable
  },
  data() {
    return {
      markdown: "",
      editorConfig: {},
      docs: [],
      url: "/download/help.json",
      toc: [
        { name: "John", id: 1 },
        { name: "Joao", id: 2 },
        { name: "Jean", id: 3 },
        { name: "Gerard", id: 4 }
      ],
      pages: [
        { name: "Juan", id: 5 },
        { name: "Edgard", id: 6 },
        { name: "Johnson", id: 7 }
      ]
    };
  },
  async created() {
    axios
      .get(this.url)
      .then((res) => {
        this.docs = res.data;
        db.docs.bulkAdd(this.docs);
      })
      .catch((err) => console.log(err, "an error occured"));

    // console.log(this.docs)
    // this.docs = await db.docs.toArray();
  },
  methods: {},
};
</script>